import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "131",
  height: "144",
  viewBox: "0 0 131 144",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("g", {
      id: "Vector",
      style: {"mix-blend-mode":"multiply"}
    }, [
      _createElementVNode("path", {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        d: "M24.9679 3.84595C53.7374 12.1643 58.1053 49.5352 77.7789 72.0456C94.7718 91.4887 127.175 99.1092 130.565 124.67C134.111 151.392 115.434 177.055 93.8681 193.326C74.5901 207.872 49.1406 204.973 24.9679 204.344C1.76099 203.74 -22.362 204.363 -40.4233 189.83C-60.2468 173.879 -69.3022 149.615 -74.0484 124.67C-80.2471 92.0897 -92.0677 54.7069 -71.0189 29.0224C-49.2641 2.47621 -8.06414 -5.70489 24.9679 3.84595Z",
        fill: "#FFF19D",
        "fill-opacity": "0.24"
      })
    ], -1)
  ])))
}
export default { render: render }